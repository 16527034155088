import '../css/homepage-en-us.scss';
import $ from '@modules/jquery-import';
import mediaQuery from '@util/media-queries';
import {Corpus, jquerySmartResize} from '@modules/lazy-imports';
import Swiper from 'swiper/bundle';

$( function () {

    var $sales = $( '#body-content' ),
        defaults = ['navigation-style:progressive'],
        bp = mediaQuery.getCurrentBreakPoint(),
        promos = $sales.find( '.carousel' );

    initCarousel( $sales, defaults );

    jquerySmartResize().then( () => {
        $( window ).smartresize( function () {
            var curBreakPoint = mediaQuery.getCurrentBreakPoint();
            if ( curBreakPoint !== bp ) {
                initCarousel( $sales, defaults );
                bp = curBreakPoint;
            }
        } );
    } );

    // reconfigure homepage banner data
    $( '#banner-hero [data-promo-position]' ).each( function ( index, el ) {
        el.dataset.promoPosition = 'Homepage Banner ' + (index + 1);
    } );

    async function initCarousel ( scope, options ) {
        await Corpus();

        var opts = options.slice.apply( options );

        scope.corpus( 'off' );

        if ( window.matchMedia( mediaQuery.min.screenBase ).matches || !window.matchMedia( 'only all' ).matches ) {
            opts.push( 'multiplier:4' );
        }
        else if ( window.matchMedia( mediaQuery.min.screenMedium ).matches ) {
            opts.push( 'multiplier:3' );
        }
        else if ( window.matchMedia( mediaQuery.min.screenSmall ).matches ) {
            opts.push( 'multiplier:2' );
        }
        else {
            opts.push( 'multiplier:1' );
        }
        // apply declarative settings

        promos.data( 'options', opts.join( ',' ).toString() );
        scope.corpus( 'carousel' );
    }
} );

$( async () => {

    $( () => {

        var swiper = new Swiper( '.my-swiperCarousel', {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1,

            loop: $( '.swiper-slide' ).length > 1 ? true : false,
            loopFillGroupWithBlank: false,
            watchOverflow: true, // This checks if there's only one slide and disables swiper if true
            autoplay: {
                delay: 5000
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        } );

        $( '.my-swiperCarousel' ).mouseenter( function () {
            swiper.autoplay.pause();
        } );

        $( '.my-swiperCarousel' ).mouseleave( function () {
            swiper.autoplay.paused == true ? swiper.autoplay.resume() : swiper.autoplay.stop();
        } );

        $( '.swiper-button-next, .swiper-button-prev, .swiper-pagination-bullet, .button' ).click( function () {
            swiper.autoplay.stop();
        } );

    } )

} )
